const linkAdapter = (data) => {
  return {
    link: {
      url: data.link.url,
      title: data.link.title,
    },
    description: data.description,
    hasFootnote: data.has_footnote,
  };
};

export default ({ data }) => {
  return {
    links: data.links?.map(linkAdapter),
    footnote: data.footnote,
  };
};
